import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {tap} from 'rxjs/operators';
import {HeaderMetaModel} from '@share/models/header/header.model';
import {AppConstant, RouteConstant} from 'src/app/constant';
import {getEnv} from '@share/services/product-search.service';
import {NotificationRestService} from '@api-module/rest/notification.rest.service';
import {SubHandlingService} from '@api-module/service/common/subscription-handling.service';
import {AccountRestService} from '@api-module/rest/account.rest.service';
import {IResponse} from '@api-module/model/common/i-response';
import {AuthState} from '@auth-module/store';
import {Store} from '@ngxs/store';

import {genRouterLink} from '@util/route';
import {IAccountBasicInfo} from '@api-module/model/account/i-account-basic-info.model';

import {MobilePostMenu, PostNewMenu} from '../../header/post-login-new-menu';
import {SecurityRestService} from '@api-module/rest/security.rest.service';
import {HoldingTabService} from '@share/services/holdings/holding-tab.service';
import {ResponsiveService} from '@core/service/responsive.service';
import {GlobalDataStorage} from '@share/storages/global-data.storage';
import {IpoService} from '@share/services/ipo/ipo-service';
import { HoldingRestService } from '@api-module/rest/holding.rest.service';

interface notification {
	id: number;
	message: string;
	sendDate: string;
	redirection: string;
	image: string;
	localeId: string;
	status: string;
	countryCode: string;
	categoryName: string;
	isRead: string;
	module: string;
	receiveDate: Date;
	sendTo: Date;
	badge: string;
}

@Component({
	selector: 'post-login-menu',
	templateUrl: './post-login-menu.component.html',
	styleUrls: ['./post-login-menu.component.scss'],
	providers: [SubHandlingService]
})

export class PostLoginMenuComponent implements OnChanges, OnInit{
	
	constructor(
		private router: Router,
		private notificationService: NotificationRestService,
		private subHandlingService: SubHandlingService,
		private securityRestService: SecurityRestService,
		private accountRestService: AccountRestService,
		private store: Store,
		private holdingTabService: HoldingTabService,
		private responsiveService: ResponsiveService,
		private globalDataStorage: GlobalDataStorage,
		private notificationRestService: NotificationRestService,
		private ipoService: IpoService,
		private holdingRestService: HoldingRestService
	){
		this.holdingTabService.activeViewHoldingTab$.subscribe(tab => {
			this.activeViewHoldingTab = tab;
			this.setMenuActive(this.router.url);
		});
	}
	
	@Input() postMenu: Array<HeaderMetaModel>;
	@Input() search: boolean;
	@Input() locale: string;
	@Input() accountSettingMenu: Array<HeaderMetaModel>;
	@Input() isCollapsed: boolean;
	@Output() updatePostloginCollapse: EventEmitter<boolean> = new EventEmitter<boolean>();

	dAccountMenu = ['fsmone.valuation.report'];

	loadingNotification: boolean = false;
	showNotification: boolean = false;

	notifications: notification[];
	hasDAccount: boolean = false;
	
	uid: string;
	accountBasicInfo: IAccountBasicInfo;

	isPostloginMenuCollapsed = true;
	postNewMenu: Array<HeaderMetaModel> = PostNewMenu;
	mobilePostMenu: Array<HeaderMetaModel> = MobilePostMenu;

	activeViewHoldingTab: string = 'accountSummary';

	isMobileView: boolean = false;
	showMobileSubMenu = false;
	selectedSubMenu: HeaderMetaModel;
	fsmPostLoginWhitelist = false;

	primaryAccount: any;
	nextTierKey = null;
	aumTopUp: number;
	grandAumLastUpdatedTime: any;
	showTopUpMessage = false;
	showDeclarePiMessage = false;
	dateFormat = this.globalDataStorage.getStorage("locale") === "ch" || this.globalDataStorage.getStorage("locale") === "zh" ? "yyyy年MM月dd日" : "dd MMM yyyy";

	readonly notificationKey: string = "notification";

	hasRegionalAccount: boolean = false;
	
	autoSweepEnabled: boolean;
	hasAutoSweepAccount: boolean = false;

	ngOnInit() {
		this.store.select(AuthState.isAuthenticated).pipe(
			tap((auth: boolean) => {
			  this.fsmPostLoginWhitelist = auth;
			})
		).subscribe();

		this.isMobileView = this.responsiveService.isMobileView();
		this.responsiveService.mobileViewEvent.subscribe(state => { this.isMobileView = state; });

		this.uid = this.store.selectSnapshot(AuthState.getId);
		if (this.uid != null || this.uid != undefined) {
			this.subHandlingService.subscribe(
				this.accountRestService.findFhkDpmsValidAccountById().pipe(
					tap((response: IResponse<Boolean>) => {
						if (response.status === AppConstant.RESPONSE_SUCCESS) {
						    if (response.data && response.data.length > 0) {
						        this.hasDAccount = true;
                            } else {
						        this.hasDAccount = false;
                            }
						}
					})
				)
			)
        	this.subHandlingService.once(
          		this.accountRestService.getAccountBasicInfo().pipe(
					tap((response: IResponse<IAccountBasicInfo>) => {
						this.accountBasicInfo = response.data;
						this.accountBasicInfo.riskAppetite = 'fsmone.risk.' + this.accountBasicInfo.riskAppetite.toLowerCase().trim().replace(/ /g,"");
						this.accountBasicInfo.iconStatus = this.accountBasicInfo.status.toLowerCase() == 'platinum plus' ? 'platinum-plus' : this.accountBasicInfo.status.toLowerCase();
						this.accountBasicInfo.status = 'fsmone.tiered.group.' + (this.accountBasicInfo.status.toLowerCase() == 'platinum plus' ? 'platinum.plus' : this.accountBasicInfo.status.toLowerCase());
					})
				)
		  	)
			this.subHandlingService.once(
				this.accountRestService.getPrimaryAccount().pipe(
				  tap((response: IResponse<IAccountBasicInfo>) => {
					  this.primaryAccount = response.data;
				  })
			  )
			)
			this.subHandlingService.once(
				this.notificationRestService.findNotificationBadgeByAccountId().pipe(
					tap((response: IResponse<number>)=>{
						let headerMetaModel: HeaderMetaModel = this.postNewMenu?.find(a=> a.titleKey === this.notificationKey);
						if(headerMetaModel){
							headerMetaModel.unreadNumber = response?.data;
						}
					})
				)
			)

			this.subHandlingService.once(
				this.accountRestService.hasRegionalMarginAcct().pipe(
					tap((response: IResponse<IAccountBasicInfo>) => {
						this.hasRegionalAccount = response.data;
					})
				)
			)
			
			this.subHandlingService.once(  // AS Valuation Report - Show this as long as client has AS history
				this.accountRestService.hasAutoSweepAccount().pipe(
					tap((response: IResponse<IAccountBasicInfo>) => {
						this.hasAutoSweepAccount = response.data;
					})
				)
			)
			
			this.holdingRestService.getNextTierTotalAum().subscribe((res:any)=>
			{
				if(AppConstant.RESPONSE_SUCCESS===res.status && res.data)
				{
					this.grandAumLastUpdatedTime = new Date();
					
					if(res.data.hasOwnProperty("isPlat"))
					{
						this.accountRestService.isProfessionalInvestorAccount().subscribe(res => 
						{
							if (AppConstant.RESPONSE_SUCCESS === res.status) 
							{
								if (res.data === false)
									this.showDeclarePiMessage = true;
							}
						});
					}
					else
					{						
						this.nextTierKey = res.data.toTierMsgBundle;
						this.aumTopUp = Number(res.data.topUpAmt);
						this.showTopUpMessage = true;
					}
				}
			});
			
			this.securityRestService.isModuleEnabled("AUTO_SWEEP_FSM").toPromise().then((response) => {
				if (response && response?.status === 'SUCCESS') {
					this.autoSweepEnabled =  response?.data;
					if (this.autoSweepEnabled) {
						const myAccountObj = this.postNewMenu.find(obj => obj.title === 'My Account');
						const cashAccountObj = myAccountObj.children.find(obj => obj.title === 'Cash Account');
						if (cashAccountObj) {
						    cashAccountObj.titleKey = 'fsmone.menu.cash.solutions';
						}
					}
				}
			});
		}
		
		this.setMenuActive(this.router.url);
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
			  this.setMenuActive(this.router.url);
			}
		});
	}

	setMenuActive(url): void {
		this.postNewMenu.forEach(module => {
			module.active = false;
			if (module.children) {
				// set sub-pages active
				module.childrenExpanded = false;
				module.children.forEach(page => {
					page.active = false;
					if (page.url != '' && url.includes(page.url)) {
						// handle holdings pages
						if (url === '/holdings') {
							if (page.value === this.activeViewHoldingTab) {
								module.active = true;
								module.childrenExpanded = true;
								page.active = true;
							}
						
						} else {
							if (page.url !== '/holdings') {
								module.active = true;
								module.childrenExpanded = true;
								page.active = true;
								// handle account setting pages
								if (page.url === '/account-setting' && url !== '/account-setting') {
									page.active = false;
								}
								if (page.url === '/account-setting/login-and-security' && url !== '/account-setting/login-and-security') {
									page.active = false;
								}
							}
						}
					}
				})
			} else {
				// set module active
				if (module.url != '' && url.includes(module.url)) {
					module.active = true;
				}
			}
		})
		this.mobilePostMenu.forEach(module => {
			module.active = false;
			// handle setting module
			if (module.title !== 'Menu') {
				// handle siscover button
				if (module.title === 'Discover') {
					if (url === '/') {
						module.active = true;
					}
				} else if (module.title === 'Holdings') {
					if (url === '/holdings') {
						module.active = true;
					}
				} else {
					if (url.includes(module.url)) {
						module.active = true;
					}
				}
			}
		});
	}
 
	navigate(path: string) {
		let url = getEnv() + '/fsm';
		if(path.indexOf(RouteConstant.ACCOUNTUPDATE_ACCOUNT_UPDATE_MENU) != -1) {
			let position:number = path.lastIndexOf('/');
			let target = path.substring(position+1);
			
			if("update-account-personal-details" == target)
			target = target + '/';
			
			let newPath = genRouterLink(RouteConstant.ACCOUNTUPDATE,RouteConstant.ACCOUNT_UPDATE_MENU,this.accountBasicInfo.refno, target);

			window.location.assign(url + newPath);
		} else if (path.indexOf('www.') != -1 || path.indexOf("fsmLogout") != -1) {
			window.location.assign(path);
		} else if (path == '/') {
			this.router.navigateByUrl(path);
		} else {
			window.location.assign(url + path);
		}

	}

	toggleNotification() {
		this.showNotification = !this.showNotification;
		const uid = this.store.selectSnapshot(AuthState.getId);
		if (this.showNotification) {
			this.subHandlingService.subscribe(
				this.notificationService.findNotifications(uid).pipe(
					tap((response) => {
						if (response.status === AppConstant.RESPONSE_SUCCESS) {
							this.notifications = response.data;
						}
					})
				)
			)
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		
	}

	checkMenu(titleKey:string) {	
		if(this.dAccountMenu.includes(titleKey)){
			if(!this.hasDAccount) {
				return false;
			}
		}

		if(titleKey == 'fsmone.margin.summary') {
			if(!this.hasRegionalAccount) {
				return false;
			}
		}

		let isCorporateAcct = this.primaryAccount?.accountType == 'C';
		if(isCorporateAcct) {
			if(titleKey == 'fsmone.electronic.direct.debit.authorization') {
				return false;
			}
		}else {
			if(titleKey == 'direct.debit.authorisation') {
				return false;
			}
		}
		
		if (titleKey == 'fsmone.auto.sweep.enrolment') {
			if (!this.autoSweepEnabled) {
				return false;
			}
		}
		
		if(titleKey == 'fsmone.auto.sweep.valuation.report') {
			if(!this.hasAutoSweepAccount) {
				return false;
			}
		}
		
		return true;
	}

	togglePostloginMenuCollapsed(isCollapsed: boolean): void {
		this.isPostloginMenuCollapsed = isCollapsed;
	}

	clickModule(selectedModule: any) {
		if (this.isCollapsed) {
			this.isCollapsed = false;
			this.updatePostloginCollapse.emit(this.isCollapsed);
			this.postNewMenu.forEach(module => {
				module.childrenExpanded = false;
			})
		}
		if(selectedModule.titleKey === this.notificationKey){
			this.subHandlingService?.once(
				this.notificationRestService?.updateBadge().pipe(
					tap(res=>{
						let headerMetaModel: HeaderMetaModel = this.postNewMenu?.find(a=> a.titleKey === this.notificationKey);
						if(headerMetaModel){
							headerMetaModel.unreadNumber = 0;
						}
					})
			))
		}
		if (selectedModule.url != '') {
			this.router.navigate([selectedModule.url]);
		} else {
			this.postNewMenu.forEach(module => {
				if (module.title === selectedModule.title) {
					module.childrenExpanded = !module.childrenExpanded;
				} else {
					if (!module.active) {
						module.childrenExpanded = false;
					}
				}
			})
		}
	}

	routeToPage(url: any, value?: string) {
		if (url) {
			this.router.navigate([url]);
			if (value) {
				if (url.includes('holdings')) {
					this.holdingTabService.setActiveViewHoldingTab(value);
				  } else if (url.includes('trade/ipo')) {
					this.ipoService.updateSelectedIpoType(value);
				  }
			}
		}
		this.toggleMobileSubMenu(false);
	}

	onClickMobileModule(selectedModule: HeaderMetaModel) {
		if (!selectedModule?.children) {
		  this.routeToPage(selectedModule.url, selectedModule.value ?? null);
		  this.toggleMobileSubMenu(false);
		} else {
			this.toggleMobileSubMenu(true, selectedModule);
		}
	}

	toggleMobileSubMenu(state, selectedModule?: HeaderMetaModel): void {
		this.showMobileSubMenu = state;
		this.selectedSubMenu = selectedModule;
	}

	onClickSubModule(selectedSubModule: any) {
		selectedSubModule.active = !selectedSubModule.active;
		this.selectedSubMenu.children.forEach(subModule => {
		  if (subModule.title != selectedSubModule.title) {
			subModule.active = false
		  }
		});
	}

	goToRewardPage(): void {
		this.router.navigate([RouteConstant.SUPPORT, RouteConstant.FEES_CHARGES, RouteConstant.REWARDS_PROGRAM]);
	}

	goToPiPage(): void {
		this.router.navigate([RouteConstant.ACCOUNT_SETTING, RouteConstant.ACTIVATED_PRODUCTS, RouteConstant.PRO_INVESTOR_DECLARATION]);
	}
}
