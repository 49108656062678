import { Injectable } from '@angular/core';
import { IPendingAccountDisplayModel } from "@api-module/model/account/i-pending-account-display.model";
import { IPendingAccountModel } from "@api-module/model/account/i-pending-account.model";
import {
  IIamAccountDisplayModel
} from '@api-module/model/authority/i-fsm-post-login.model';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Observable, of } from "rxjs";
import { AccountOpeningConstant, AppConstant, StateConstant } from 'src/app/constant';
import {
  AccountAcceptAiDeclaration,
  LoginSuccess,
  Logout,
  ResumeAuth,
  SetAccountDetailsUpdate,
  SetAuthToken,
  SetCdsAccountNo,
  SetCdsEnabled,
  SetDkaUpdate,
  SetEmailUpdated,
  SetEmisAcctUpdate,
  SetFepFlag,
  SetForceEmailUpdated,
  SetFpxBypass, SetIsAcctBlocked,
  SetMarkAsRead,
  SetNum2FA,
  SetOptInOtp,
  SetPendingActions,
  SetPersonalCreated,
  SetPwdExpReminded,
  SetReRoute2FA,
  SetResetPasswordUpdated,
  SetRiskProfileUpdate,
  SetRpqExpiry,
  SetShowFpx,
  SetFtlHkStockActivation
} from '../action/auth.action';

export interface AuthStateModel {
  id: string | null;
  authenticated: boolean;
  accounts: Array<IIamAccountDisplayModel>;
  pendingAccount: Array<IPendingAccountModel>;
  primaryAccount: IIamAccountDisplayModel;
  lastLogin: any;
  token: string;
  refreshToken: string;
  initial: string;
  tokenIssued: any;
  loginTime: any;
  emailUpdate: boolean;
  forceEmailUpdate: boolean;
  passwordUpdate: boolean;
  pendingAcctDisplay: IPendingAccountDisplayModel;
  riskProfileUpdate: boolean;
  emisAcctUpdate:boolean;
  dkaUpdate:boolean;
  accountDetailsUpdate: boolean;
  fpxBypass: boolean;
  acctStatus:string;
  pendingAccountActions: AuthPendingActionStateModel;
  otpInOtp: boolean;
  isPersonalCreated:boolean;
  cdsEnabled: boolean;
  cdsAccountNo: string;
  refno: string;
  showFpx: boolean;
  fepFlag: boolean;
	grantingTicket: string;
  markAsRead: string;
  num2FA: string;
  reRoute2FA: boolean;
  authByToken: boolean
  rpqExpiryReminder: boolean;
  // for delay purpose
  rpqExpiryReminderApi: boolean;
  passwordExpiryReminded: boolean;
  passwordExpiryRemindedApi: boolean;
  displayIfastGlobalView: boolean;
  isAcctBlocked: boolean;
  ftlHkStockActivation: boolean;
}

export interface AuthPendingActionStateModel {
  newTncAgreement: boolean;
  fhTncAgreement: boolean;
  validatedPrivacyNotice: boolean;
}

const stateDefaults: AuthStateModel = {
  id: null,
  authenticated: false,
  accounts: [],
  pendingAccount: [],
  lastLogin: null,
  token: null,
  refreshToken: null,
  initial: null,
  primaryAccount: null,
  tokenIssued: null,
  loginTime: null,
  emailUpdate: false,
  forceEmailUpdate: false,
  passwordUpdate: false,
  pendingAcctDisplay: null,
  riskProfileUpdate: false,
  emisAcctUpdate:false,
  dkaUpdate: false,
  accountDetailsUpdate: false,
  fpxBypass: false,
  acctStatus:null,
  pendingAccountActions: {
    newTncAgreement: true,
    fhTncAgreement: true,
    validatedPrivacyNotice: false,
  },
  otpInOtp: false,
  isPersonalCreated:false,
  cdsEnabled: false,
  cdsAccountNo: null,
  refno: null,
  showFpx: false,
  fepFlag: false,
	grantingTicket:null,
  markAsRead: null,
  num2FA: '',
  reRoute2FA: false,
  authByToken: false,
  rpqExpiryReminder: true,
  rpqExpiryReminderApi: true,
  passwordExpiryReminded: true,
  passwordExpiryRemindedApi: true,
  displayIfastGlobalView: false,
  isAcctBlocked: false,
  ftlHkStockActivation: false
};

@State<AuthStateModel>({
  name: StateConstant.AUTH,
  defaults: stateDefaults
})
@Injectable()
export class AuthState {
  @Selector()
  static getAuthData(state: AuthStateModel): AuthStateModel {
    return {
      ...state
    }
  }

  @Selector()
  static isAuthenticated(state: AuthStateModel): boolean {
    if(state.token!=null){
      const helper = new JwtHelperService();
        if(helper.isTokenExpired(state.token)){
          return false;
      }
      return true;
    }
    return false;   
  }

  @Selector()
  static isAllAuthenticated(state: AuthStateModel): boolean 
  {
    return state.authenticated || state.authByToken;
  }

  @Selector()
  static isCasAuthenticated(state: AuthStateModel): boolean {
    return state.authenticated;
  }

  @Selector()
  static isForceAccountUpdate(state: AuthStateModel): boolean {
    return state.accountDetailsUpdate;
  }

  @Selector()
  static isForceResetPwd(state: AuthStateModel): boolean {
	return state.passwordUpdate;
  }
  
  @Selector()
  static isForceRiskProfileUpdate(state: AuthStateModel): boolean {
    return state.riskProfileUpdate;
  }

  @Selector()
  static isEmisUpdate(state: AuthStateModel): boolean {
    return state.emisAcctUpdate;
  }

  @Selector()
  static isDkaUpdate(state: AuthStateModel): boolean {
    return state.dkaUpdate;
  }

  @Selector()
  static isRpqExpiry(state: AuthStateModel): boolean {
    return state.rpqExpiryReminder;
  }
  
  @Selector()
  static ftlHkStockActivation(state: AuthStateModel): boolean {
    return state.ftlHkStockActivation;
  }

  @Selector()
  static getPrimaryAccount(state: AuthStateModel): IIamAccountDisplayModel {
    return state.primaryAccount;
  }

  @Selector()
  static getAccounts(state: AuthStateModel): Array<IIamAccountDisplayModel> {
    return state.accounts;
  }

  @Selector()
  static getId(state: AuthStateModel): string {
    return state.id;
  }

  @Selector()
  static getToken(state: AuthStateModel): string {
    return state.token;
  }

  @Selector()
  static getRefreshToken(state: AuthStateModel): string {
    return state.refreshToken;
  }

  @Selector()
  static getPrimaryDisplay(state: AuthStateModel) {
    return {
      ...state.primaryAccount,
      initial: state.initial
    }
  }

  @Selector()
  static isPendingAccount(state: AuthStateModel) {
    return state.acctStatus === AccountOpeningConstant.ACCOUNT_STATUS_PENDING && state.accounts.length===0;
  }

  @Selector()
  static getPendingDisplay(state: AuthStateModel) {
    return {
      ...state.pendingAcctDisplay,
      initial: state.initial
    }
  }

  @Selector()
  static getFpxBypass(state: AuthStateModel) {
    return state.fpxBypass
  }

  @Selector()
  static isProcessingAccount(state: AuthStateModel) {
    return state.acctStatus === AccountOpeningConstant.ACCOUNT_STATUS_PROCESSING
  }

  @Selector()
  static isEmisProcessingAccount(state: AuthStateModel) {
    return state.accounts[0].emisFlag === AppConstant.YES_FLAG && state.accounts[0].buyDisabled === AppConstant.YES_FLAG && state.accounts[0].sellDisabled === AppConstant.YES_FLAG
  }

  @Selector()
  static isPersonalCreated(state: AuthStateModel): boolean {
    return state.isPersonalCreated;
  }

  @Selector()
  static getPendingAccountActions(state: AuthStateModel) {
    return state.pendingAccountActions;
  }

  @Selector()
  static isTempAccount(state: AuthStateModel) {
    return state.acctStatus === null;
  }

  @Selector()
  static isCdsEnabled(state: AuthStateModel) {
    return state.cdsEnabled;
  }

  @Selector()
  static getCdsAccountNo(state: AuthStateModel) {
    return state.cdsAccountNo;
  }

  @Selector()
  static isFepFlag(state: AuthStateModel): boolean {
    return state.fepFlag;
  }

  @Selector()
  static getLoginTime(state: AuthStateModel): Date {
    return state.loginTime;
  }

  @Selector()
  static getNum2FA(state: AuthStateModel): string {
    return state.num2FA;
  }

  @Selector()
  static getReRoute2FA(state: AuthStateModel): boolean {
    return state.reRoute2FA;
  }

  @Selector()
  static isPasswordExpiryReminded(state: AuthStateModel): boolean {
    return state.passwordExpiryReminded;
  }

  @Selector()
  static isDisplayIfastGlobalView(state: AuthStateModel): boolean {
    return state.displayIfastGlobalView;
  }

  @Selector()
  static getIsAcctBlocked(state: AuthStateModel): boolean {
    return state.isAcctBlocked;
  }

	@Action(LoginSuccess)
  	loginSuccess({patchState, dispatch}: StateContext<AuthStateModel>, action: LoginSuccess) {
		const {authenticationDisplay, lastLogin, refreshToken, token, casAuthenticated} = action.user;
	    patchState({
          id: authenticationDisplay.id,
          loginTime: new Date(),
          authenticated: casAuthenticated,
          dkaUpdate: authenticationDisplay.forceUpdateDerivativesKnowledge,
          riskProfileUpdate: authenticationDisplay.forceUpdateRiskProfile,
          rpqExpiryReminderApi: authenticationDisplay.rpqExpiryReminder,
          passwordExpiryRemindedApi: authenticationDisplay.passwordExpiryReminded,
          passwordUpdate: authenticationDisplay.resetPwd,
          displayIfastGlobalView: authenticationDisplay.displayIfastGlobalView,
          ftlHkStockActivation: authenticationDisplay.ftlHkStockActivation
	    });
	    return dispatch(new SetAuthToken(token, refreshToken));
	}

  @Action(SetAuthToken)
  setAuthToken({patchState}: StateContext<AuthStateModel>, action: SetAuthToken) {
    patchState({
      token: action.token || stateDefaults.token,
      refreshToken: action.refreshToken || stateDefaults.refreshToken,
      tokenIssued: Date.now()
    });
  }

  @Action(ResumeAuth)
  resumeAuthSession({patchState, getState}: StateContext<AuthStateModel>, action: ResumeAuth) 
  {
    if (getState().authenticated) {
      return;
    }

    const storedState: AuthStateModel = action.context;
    const {id, token, accounts, primaryAccount} = storedState;

    if (!id) 
    {
		return;
    }

    patchState({
      ...storedState
    });
  }

  @Action(SetResetPasswordUpdated)
  setResetPasswordUpdated({patchState}: StateContext<AuthStateModel>, action: SetResetPasswordUpdated) {
    patchState({
      passwordUpdate: action.passwordUpdate || stateDefaults.passwordUpdate
    });
  }

  @Action(SetForceEmailUpdated)
  setForceEmailUpdated({patchState}: StateContext<AuthStateModel>, action: SetForceEmailUpdated) {
    patchState({
      forceEmailUpdate: action.forceEmailUpdate || stateDefaults.forceEmailUpdate
    });
  }

  @Action(SetOptInOtp)
  setOptInOtpFlag({patchState}: StateContext<AuthStateModel>, action: SetOptInOtp) {
    patchState({
      otpInOtp: action.otpInOtp || stateDefaults.otpInOtp
    });
  }

  @Action(SetShowFpx)
  setShowFpx({patchState}: StateContext<AuthStateModel>, action: SetShowFpx) {
    patchState({
      showFpx: action.showFpx
    });
  }

  @Action(SetFepFlag)
  setFepFlag({patchState}: StateContext<AuthStateModel>, action: SetFepFlag) {
    patchState({
      fepFlag: action.fepFlag
    });
  }

  @Action(Logout)
  logout({setState}: StateContext<AuthStateModel>) {
    setState({
      ...stateDefaults
    });
  }

  @Action(SetAccountDetailsUpdate)
  setAccountDetailsUpdate({patchState}: StateContext<AuthStateModel>, action: SetAccountDetailsUpdate) {
    patchState({
      accountDetailsUpdate: action.updateFlag
    });
  }

  @Action(SetRiskProfileUpdate)
  setRiskProfileUpdate({patchState}: StateContext<AuthStateModel>, action: SetRiskProfileUpdate) {
    patchState({
      riskProfileUpdate: action.updateFlag
    });
  }

  @Action(SetDkaUpdate)
  setDkaUpdate({patchState}: StateContext<AuthStateModel>, action: SetDkaUpdate) {
    patchState({
      dkaUpdate: action.updateFlag
    });
  }

  @Action(AccountAcceptAiDeclaration)
  acceptAiDeclaration({ patchState, getState }: StateContext<AuthStateModel>, action: AccountAcceptAiDeclaration) {
    const declareRefno: string = action.refno;
    const { accounts } = getState();
    const updatedAccounts = accounts.slice().map((account: IIamAccountDisplayModel) => {
      if (account.refno === declareRefno) {
        return {
          ...account,
          aiFlag: AppConstant.YES_FLAG
        };
      }
      else {
        return account;
      }
    });

    patchState({
      accounts: [...updatedAccounts]
    });
  }

  @Action(SetFpxBypass)
  setFpxBypass({patchState}: StateContext<AuthStateModel>, action: SetFpxBypass) {
    patchState({
      fpxBypass: action.fpxBypass
    });
  }

  @Action(SetPendingActions)
  setAccountPendingActions({ patchState, getState }: StateContext<AuthStateModel>, action: SetPendingActions) {
    const state = getState();

    patchState({
      pendingAccountActions: {
        ...state.pendingAccountActions,
        ...action.pendingActions,
      }
    })
  }

  @Action(SetEmailUpdated)
  setEmailUpdated({patchState}: StateContext<AuthStateModel>, action: SetEmailUpdated) {
    patchState({
    	emailUpdate: action.emailUpdate || stateDefaults.emailUpdate
    });
  }

  @Action(SetPersonalCreated)
  setPersonalCreated({patchState}: StateContext<AuthStateModel>, action: SetPersonalCreated) {
    patchState({
      isPersonalCreated: action.isPersonalCreated || stateDefaults.isPersonalCreated
    });
  }

  @Action(SetEmisAcctUpdate)
  setEmisAcctUpdate({patchState}: StateContext<AuthStateModel>, action: SetEmisAcctUpdate) {
    patchState({
      emisAcctUpdate: action.updateFlag
    });
  }

  @Action(SetCdsEnabled)
  setCdsEnabled({patchState}: StateContext<AuthStateModel>, action: SetCdsEnabled) {
    patchState({
      cdsEnabled: action.cdsEnabled || stateDefaults.cdsEnabled
    });
  }

  @Action(SetCdsAccountNo)
  setCdsAccountNo({patchState}: StateContext<AuthStateModel>, action: SetCdsAccountNo) {
    patchState({
      cdsAccountNo: action.cdsAccountNo || stateDefaults.cdsAccountNo
    });
  }
  @Action(SetMarkAsRead)
  markAsRead({patchState}: StateContext<AuthStateModel>, action: SetMarkAsRead) {
    patchState({
      markAsRead: action.markAsRead || stateDefaults.markAsRead
    });
  }

  @Action(SetNum2FA)
  setNum2FA({patchState}: StateContext<AuthStateModel>, action: SetNum2FA) {
    patchState({
      num2FA: action.num2FA || stateDefaults.num2FA,
      authByToken: true
    });
  }

  @Action(SetReRoute2FA)
  setReRoute2FA({patchState}: StateContext<AuthStateModel>, action: SetReRoute2FA) {
    patchState({
      reRoute2FA: action.reRoute2FA || stateDefaults.reRoute2FA
    });
  }

  @Action(SetRpqExpiry)
  setRpqExpiry({patchState}: StateContext<AuthStateModel>, action: SetRpqExpiry) {
    patchState({
      rpqExpiryReminder: action.rpqExpiryReminder
    });
  }

  @Action(SetPwdExpReminded)
  SetPwdExpReminded({patchState}: StateContext<AuthStateModel>, action: SetPwdExpReminded) {
    console.log('patch value > '+action.passwordExpiryReminded)
    patchState({
      passwordExpiryReminded: action.passwordExpiryReminded
    });
  }

  @Action(SetIsAcctBlocked)
  setAcctBlocked({patchState}: StateContext<AuthStateModel>, action: SetIsAcctBlocked) {
    patchState({
      isAcctBlocked: action.isAcctBlocked || stateDefaults.isAcctBlocked,
    });
  }
  
  @Action(SetFtlHkStockActivation)
  setFtlHkStockActivation({patchState}: StateContext<AuthStateModel>, action: SetFtlHkStockActivation) {
    patchState({
      ftlHkStockActivation: action.ftlHkStockActivation || stateDefaults.ftlHkStockActivation,
    });
  }

  @Selector()
  static isMarkAsRead(state: AuthStateModel): Observable<any> {
    return of(state.markAsRead);
  }

  @Selector()
  static getMarkAsRead(state: AuthStateModel){
    return state.markAsRead;
  }
}
