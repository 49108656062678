import { Location, LocationStrategy } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { Router } from '@angular/router';
import { IBannerModel } from '@api-module/model/banner/i-banner-model';
import { AccountOpeningRestService } from '@api-module/rest/account/account-opening.rest.service';
import { BannerRestService } from '@api-module/rest/banner.rest.service';
import { AuthService } from '@auth-module/service/auth.service';
import { AuthState } from '@auth-module/store';
import { AppConstant, RouteConstant } from '@constant';
import { HeaderPositionService } from '@core/service/header-position.service';
import { ResponsiveService } from '@core/service/responsive.service';
import { Select, Store } from '@ngxs/store';
import { AesEncryptionService } from "@share/services/aes-encryption.service";
import { getEnvFsmone, getEnvPostLogin } from '@share/services/product-search.service';
import { AccountOpeningStorage } from '@share/storages/account/account-opening.storage';
import { HeaderPositioningService } from 'b2c-ui/core';
import { B2CHeaderMetaModel, OVERLAY_ID } from 'b2c-ui/header';
import { I18nTransKey } from 'b2c-ui/i18n';
import * as _ from 'lodash';
import { Observable, Subject, Subscription, fromEvent } from 'rxjs';
import { debounceTime, filter, takeUntil, tap } from 'rxjs/operators';
import { defaultCountry } from 'src/app/constant/country.constant';
import { GlobalState } from 'src/app/core/store';
import { IgvLoginIconComponent } from 'src/app/share/components/igv-login-icon/igv-login-icon.component';
import { HeaderMetaModel } from 'src/app/share/models/header/header.model';
import { GlobalDataStorage } from 'src/app/share/storages/global-data.storage';
import { headerSiteMapWithBanner } from '../../header/header.menu';

@Component({
  selector: 'b2cweb-header',
  templateUrl: './web-header.component.html',
  styleUrls: ['./web-header.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [HeaderPositioningService, HeaderPositionService]
})
export class WebHeaderComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input() appBarTemplates: Array<TemplateRef<any>>;
  @Input() langTemplates: Array<TemplateRef<any>>;

  @Input() structure: Array<HeaderMetaModel>;

  @Input() actionTemplates: Array<TemplateRef<any>>;
	@Input() siteMapTemplate: Array<TemplateRef<any>>;

  @Input() set country(country: HeaderMetaModel) {
    this._countries = defaultCountry.filter(({ title }) => title !== country.title);
    this._country = country;
  }

  @Input() topWrapperId: string;
  @Input() reflowFlag: boolean = false;
  @Input() auth: boolean;
  @Input() authCas: boolean;
  @Input() auth2FA: boolean;
  @Input() displayIfastGlobalView: boolean;
  @Input() isPostloginMenuCollapsed: boolean = false;
  @Input() newMenuEnabled: boolean = false;

  @Output() navigate: EventEmitter<any> = new EventEmitter<any>();
  @Output() reflowFlagChange: EventEmitter<any> = new EventEmitter<any>();

	@Output() isLogout: boolean = false;
  @Output() updatePostloginCollapse: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('header', { static: false }) header: any;
  @ViewChild('pusher', { static: false }) pusher: any;
	@ViewChild('expandedMenu', { static: false }) expandedMenu: ElementRef;
	@ViewChild('arrow', { static: false }) arrow: ElementRef;
  @ViewChild(IgvLoginIconComponent) igvLoginIcon: IgvLoginIconComponent;
 /* @ContentChild(HeaderSearchComponent, { static: true }) searchComponent: HeaderSearchComponent;*/

	@Select(GlobalState.isMobile) isMobile$: Observable<boolean>;

  @Output() search: boolean = false;
  searchOverlay: Element;
  _country: HeaderMetaModel;
  _countries: Array<HeaderMetaModel>;

	styles = {
		'gridTemplateColumns':5,
		'gridAutoFlow':'columns'
	}

  locale: string = 'en';

  readonly I18nTransKey = I18nTransKey;
  readonly positionEvent$: Observable<any> = this.hpService.positionObs$;
  private readonly destroySubject: Subject<any> = new Subject<any>();
  isNotificationVisible = false;

  isMobileView: boolean = false;
  showPreloginMobileMenu = false;
  showPreloginMenu = true;

  resizeSubscription: Subscription;

	backgroundImg(path:string):any {
		return 'background-image : url("' + path + '"); background-repeat: no-repeat; background-position: center; background-size: cover;'
	}

  showCorp = false;
  isLoadingMenu = false

  structureWithBanner: Array<HeaderMetaModel>;
  bannerList = [];
  BANNER_PATH = 'articleFiles/webbanners/HK/' + this.globalDataStorage.getStorage('locale') + '/';

  maintenanceContent: any;
  maintenanceBannerVisible = false;

  constructor(
    private renderer: Renderer2,
    private hpService: HeaderPositioningService,
    private headerPositionService: HeaderPositionService,
    private cdRef: ChangeDetectorRef,
		private router: Router,
		private locationStrategy: LocationStrategy,
		private location: Location,
		private store: Store,
    private globalDataStorage: GlobalDataStorage,
    private authService: AuthService,
    private responsiveService: ResponsiveService,
		private aoRestService: AccountOpeningRestService,
    private aoStorage: AccountOpeningStorage,
    private bannerRestService: BannerRestService,
    private aesEncryptionService: AesEncryptionService
  ) {
    this.locale = this.globalDataStorage.getStorage('locale') || 'ch';
    window.scrollTo({
      top: 0,
      left: 0
    });
  }

  ngOnInit(): void {
    if (localStorage.getItem('maintenanceBannerDisplayed') !== 'Y') {
      this.getMaintenanceAlert();
    }
    this.structureWithBanner = _.cloneDeep(headerSiteMapWithBanner);
    this.findMenuBanners();
    
    this.store.select(AuthState.isAuthenticated).pipe(
			tap((auth: boolean) => {
				this.auth = auth;
        this.isLoadingMenu = true;
        if (this.auth) {
          this.aoRestService.additionalAccountLandingCheck().subscribe((checkRes: any) => {
            if (AppConstant.RESPONSE_SUCCESS === checkRes.status && 'auth-corp' === checkRes.data) {
              this.showCorp = true;
            } else {
              this.showCorp = false;
            }
          }).add(() => {
            this.structureWithBanner.forEach(module => {
              if (module.title === 'OUR SERVICES') {
                module.children.forEach(section => {
                  if (section.title === 'INVESTMENT ACCOUNT') {
                    if (this.showCorp) {
                      section.children = section.children.filter(page => ['Corporate'].includes(page.title));
                    } else {
                      section.children = section.children.filter(page => ['Personal', 'Discretionary', 'Beneficiary'].includes(page.title));
                    }
                    this.isLoadingMenu = false;
                    this.cdRef.markForCheck();
                    this.cdRef.detectChanges();
                  }
                })
              }
            });
          });
        } else {
          this.structureWithBanner.forEach(module => {
            if (module.title === 'OUR SERVICES') {
              module.children.forEach(section => {
                if (section.title === 'INVESTMENT ACCOUNT') {
                  section.children.forEach(page => {
                    if (page.title !== 'Corporate'){
                      page.url = RouteConstant.ACCOUNT_OPENING;
                    }
                  });
                  this.isLoadingMenu = false;
                  this.cdRef.markForCheck();
                  this.cdRef.detectChanges();
                }
              })
            }
          });
        }
			})
		).subscribe();

		this.store.select(AuthState.isCasAuthenticated).pipe(
			tap((authCas: boolean) => this.authCas = authCas)
		).subscribe();

    this.store.select(AuthState.isDisplayIfastGlobalView).pipe(
			tap((displayIfastGlobalView: boolean) => this.displayIfastGlobalView = displayIfastGlobalView)
		).subscribe();

    if ((this.auth || this.authCas) && this.auth2FA && this.displayIfastGlobalView) {
      this.structureWithBanner.push({
        title: 'iFAST Global View',
        clickable: true,
        icon: 'assets/images/header/ifast-global-view-logo.svg',
      });
    }

    this.isMobileView = this.responsiveService.isMobileView();
    this.cdRef.markForCheck();
    this.cdRef.detectChanges();
		this.responsiveService.mobileViewEvent.subscribe(state => {
      this.isMobileView = state;
      this.cdRef.markForCheck();
      this.cdRef.detectChanges();
    });
    this.resizeSubscription = fromEvent(window, 'resize').pipe(debounceTime(100)).subscribe(event => {
      this.headerPositionService.calculatePusherHeight(this.header, this.pusher);
      if (this.showPreloginMobileMenu) {
        this.showPreloginMobileMenu = false;
      }
    });

    const element: Element = document.createElement('div');
    element.id = 'b2c-search-overlay';
    document.body.appendChild(element);

    this.searchOverlay = document.getElementById(OVERLAY_ID);
  
    fromEvent(this.searchOverlay, 'click')
      .pipe(
        filter(() => this.search),
        tap(() => this.toggleSearch()),
        takeUntil(this.destroySubject)
      )
      .subscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.reflowFlag && this.reflowFlag) {
      this.hpService.reflow();
      this.reflowFlag = false;
      this.reflowFlagChange.emit(this.reflowFlag);
    }
    if (changes.newMenuEnabled) {
      setTimeout(()=>{
        this.headerPositionService.calculatePusherHeight(this.header, this.pusher);
      }, 300);
    }
  }

  ngAfterViewInit(): void {
    this.hpService.findHostWrapper(this.topWrapperId);
    this.hpService.initScroll();
    this.headerPositionService.calculatePusherHeight(this.header, this.pusher);
  }

  ngOnDestroy(): void {
    if (this.searchOverlay && this.searchOverlay.parentNode) {
      this.searchOverlay.parentNode.removeChild(this.searchOverlay);
    }
    if (this.resizeSubscription) this.resizeSubscription.unsubscribe();
  }

  findMenuBanners() {
		this.bannerRestService.findMenuBannerList().subscribe((response: any) => {
			if (AppConstant.RESPONSE_SUCCESS == response.status) {
				this.bannerList = response.data.map((data: IBannerModel) => { return data });
			}
		});
	}

  getMaintenanceAlert(): void {
    this.bannerRestService.getMaintenanceAlert().subscribe((response: any) => {
      if (AppConstant.RESPONSE_SUCCESS == response.status) {
        this.maintenanceContent = response.data;
        this.maintenanceBannerVisible = localStorage.getItem('maintenanceBannerDisplayed') === 'Y' ? false : true;
        this.cdRef.detectChanges();
        setTimeout(()=>{
          this.headerPositionService.calculatePusherHeight(this.header, this.pusher);
        }, 300);
      } else {
        this.maintenanceContent = null;
        this.maintenanceBannerVisible = false;
        localStorage.setItem('maintenanceBannerDisplayed', 'Y');
      }
    });
  }

  onCloseMaintenanceBanner(): void {
    this.maintenanceBannerVisible = false;
    localStorage.setItem('maintenanceBannerDisplayed', 'Y');
    setTimeout(()=>{
      this.headerPositionService.calculatePusherHeight(this.header, this.pusher);
    }, 300);
  }

  toggleSearch() {
    this.search = !this.search;
    if (this.search) {
      this.searchOverlay.classList.add('d-block');
    } else {
      this.searchOverlay.classList.remove('d-block');
    }
    this.cdRef.markForCheck();
    this.cdRef.detectChanges();
  }

  emitNavigationEvent(
    title: string,
    url?: string,
		postLogin?: boolean,
    element?: ElementRef,
    arrow?: ElementRef,
    preventReset?: boolean
  ) 
	{
		//temporary link to fundsupermart pages
		let FSM = '/fsm';
		//temporary solution
		
		if (url.indexOf('www.')!=-1) 
		{
			//facebook link
			window.location.assign(url);
		} 
		else if(url == '/') 
		{
			this.navigate.emit(
			{
		      title,
		      url
    		})
		} 
		else if (url == 'logout') 
		{
			this.isLogout = true;
		} 
		else 
		{
			if (postLogin)
			{
				window.location.assign(getEnvPostLogin()+FSM+url);
			}
			else
			{
        this.router.navigate([url]);
			}
		}
		
    if (element) {
      this.renderer.setStyle(element, 'display', 'none');

      if (!preventReset) {
        setTimeout(() => this.renderer.removeStyle(element, 'display'), 1);
      }
    }

    if (arrow) {
      this.renderer.setStyle(arrow, 'display', 'none');
    }
		
  }

  resetMenuState(element: ElementRef, arrow: ElementRef) {
    if (element) {
      this.renderer.removeStyle(element, 'display');
    }

    if (arrow) {
      this.renderer.removeStyle(arrow, 'display');
    }
  }

  calculateGrid(children: Array<B2CHeaderMetaModel>) {
		if (children?.length > 5)
			return '1fr 1fr 1fr 1fr 1fr';
		else
    	return children ? children.map(_ => 'auto').join(' ') : '';
  }

  getGridTemplate(children: Array<B2CHeaderMetaModel>, childrenGrid: Array<any>) {
    return "repeat(" + (childrenGrid?.length ? childrenGrid?.length : children.length) + ", 1fr)";
  }

  togglePostloginMenuCollapsed(): void {
		this.isPostloginMenuCollapsed = !this.isPostloginMenuCollapsed;
		this.updatePostloginCollapse.emit(this.isPostloginMenuCollapsed);
	}

  toggleNotification() {
    this.isNotificationVisible = !this.isNotificationVisible;
  }

  clickedOutsideNotification(): void {
    this.isNotificationVisible = false;
  }

  goToPage(page: string): void {
    if (page === 'FAQ') {
      this.router.navigate([RouteConstant.SUPPORT, RouteConstant.CLIENT_SUPPORT, RouteConstant.FAQ]);
    } else if (page === 'LOGIN') {
      this.router.navigate([RouteConstant.LOGIN]);
    } else if (page === 'ACCOUNT_OPENING') {
      this.router.navigate([RouteConstant.ACCOUNT_OPENING]);
    }
  }

  logout() {
    this.authService.logout();
  }

  togglePreloginMobileMenu(): void {
    this.showPreloginMobileMenu = !this.showPreloginMobileMenu;
    this.structure.forEach(module => { module.active = false });
  }

  onClickModule(selectedModule: HeaderMetaModel) {
    if (selectedModule.title == 'iFAST TV') {
      const url = selectedModule.url as string;
      window.open(url, '_blank');
    } else if (selectedModule.title == 'iFAST Global View') {
      this.igvLoginIcon.submitForm(true);
    } else {
      selectedModule.active = !selectedModule.active;
      this.structure.forEach(module => {
        if (module.title != selectedModule.title) {
          module.active = false
        }
      });
      if (!selectedModule?.children && !selectedModule?.icon) {
        this.routeToPage(selectedModule.url);
      }
    }
  }

  routeToPage(url: any, value?: string, ) {
    if (url) {
      this.showPreloginMenu  = false;
      this.router.navigate([url]);
      if (url === '/account-opening' && value != null) {
        this.aoStorage.selectedAoType.next(value);
      }
    }
    this.showPreloginMobileMenu = false;
  }

  getBannerName(bannerTitle: string): string {
    if (this.bannerList) {
      var banner = _.find(this.bannerList, (banner) => { return banner.title === bannerTitle });
      return banner && banner.pictureFile ? banner.pictureFile : '';
    }
    return '';
  }

	bannerOnNavigate(bannerTitle: string): void 
	{
		if (this.bannerList) 
		{
			var banner = _.find(this.bannerList, (banner) => { return banner.title === bannerTitle });
      
			if (banner && banner.bannerAdBean && banner.bannerAdBean.urlLink) 
			{
				var targetDomain = ".fsmone.com.hk/";
				var navEnv = getEnvFsmone();
				var bannerUrl = banner.bannerAdBean.urlLink;
		 	 
				// if banner ad URL belongs to own domain and not in accordance to current environment
				if (bannerUrl.indexOf(targetDomain) > -1 && bannerUrl.indexOf(navEnv) == -1) 
				{
					var targetDomIndex:number = bannerUrl.indexOf(targetDomain);
					var domLength:number = targetDomain.length;
					var extDomain = bannerUrl.substring(0, targetDomIndex+domLength);
					bannerUrl = bannerUrl.replace(extDomain,navEnv+"/");
				}

				window.open(bannerUrl, '_blank');
			}
		}
	}
}
