import { Injectable } from '@angular/core';
import { HttpParamsService } from '../../service/http-params.service';
import { HttpClient } from '@angular/common/http';
import { ApiConstant } from '@api-module/api.endpoint.constant';
import { PrimaryAccountOpeningInfoBean } from '@api-module/model/account/i-account-opening.model';
import {AccountOpeningConstant} from "@constant";

@Injectable({
  providedIn: 'root'
})

export class AccountOpeningRestService {
	readonly BASE_URL: string = ApiConstant.ACCOUNT_OPENING;
	readonly PROTECTED_BASE_URL: string = ApiConstant.PROTECTED + ApiConstant.ACCOUNT_OPENING;
	
	constructor(private http: HttpClient, private paramService: HttpParamsService) {}
	
	getCountryList() {
    	return this.http.get(this.BASE_URL + ApiConstant.GET_COUNTRY_LIST, {});
  	}

	getIdIssuerCountryList() {
    	return this.http.get(this.BASE_URL + ApiConstant.GET_ID_ISSUE_COUNTRY_LIST, {});
  	}

	getOccupationList() {
    	return this.http.get(this.BASE_URL + ApiConstant.GET_OCCUPATION_LIST, {});
  	}

	getBusinessNatureList() {
    	return this.http.get(this.BASE_URL + ApiConstant.GET_BUSINESS_NATURE_LIST, {});
  	}

	getBankList() {
    	return this.http.get(this.BASE_URL + ApiConstant.GET_BANK_LIST, {});
  	}

	getFaftCountryList() {
    	return this.http.get(this.BASE_URL + ApiConstant.GET_FAFT_COUNTRY_LIST, {});
  	}

	accountOpeningPreCheck(preCheckDetails : any){
    	return this.http.post(this.BASE_URL + ApiConstant.SUBMISSION_PRE_CHECK, preCheckDetails);
	}

	additionalAccountLandingCheck(){
		return this.http.post(this.PROTECTED_BASE_URL + ApiConstant.ADD_ACCOUNT_LANDING_CHECK, {});
	}
	
	additionalAccountPrecheck(paramAccountType: string){
		const params = this.paramService.buildHttpParams({paramAccountType});
		return this.http.post(this.PROTECTED_BASE_URL + ApiConstant.ADD_ACCOUNT_PRECHECK, {}, {params}); 
	}
	
	getAdditionalAccountDetails(){
		return this.http.post(this.PROTECTED_BASE_URL + ApiConstant.GET_ADD_ACCOUNT_DETAILS, {}, {}); 
	}
	
	submitAccountOpening(acctOpenDetails : PrimaryAccountOpeningInfoBean){
		if(acctOpenDetails.iamAccountBean.accountRole==AccountOpeningConstant.ACCOUNT_ROLE_PRIMARY){
			return this.http.post(this.BASE_URL + ApiConstant.SUBMIT_ACCOUNT_OPENING, acctOpenDetails);
		} else {
			return this.http.post(this.PROTECTED_BASE_URL + ApiConstant.SUBMIT_ADD_ACCOUNT_OPENING, acctOpenDetails);
		}
	}

	validateHkid(hkid: string){
		var body = {hkid: hkid};
		return this.http.post(this.BASE_URL + ApiConstant.VALIDATE_HKID, body);
	}

	printAccountOpeningFormPdf(){
		return this.http.post(this.BASE_URL + ApiConstant.PRINT_ACCT_FORM_PDF, {}, {});
	}

	checkIdExist(id: string){
		const params = this.paramService.buildHttpParams({id});
		return this.http.post(this.BASE_URL + ApiConstant.ID_EXIST, {}, {params});
	}
	
	searchLocalAddress(address: string){
		const params = this.paramService.buildHttpParams({address});
		return this.http.post(this.BASE_URL + ApiConstant.GET_ACCOUNT_OPENING_ADDRESS_LOOKUP, {}, {params});
	}
	
	getAvailableTimeslotAccOpening(apmtDate){
		const params = this.paramService.buildHttpParams({apmtDate});
		return this.http.post(this.BASE_URL + ApiConstant.GET_CIS_AVAILABLE_TIMESLOT_ACC_OPENING, {},{params});
	}

	getBookedAppointmentAccOpening(){
		return this.http.post(this.BASE_URL + ApiConstant.GET_BOOKED_APPOINTMENT_ACC_OPENING, {},{});
	}
	
	submitAppointmentRequestAccOpening(prefDate,prefTime){	
		let data = new FormData();
		data.append('prefDate',prefDate);
		data.append('prefTime',prefTime);
		return this.http.post(this.BASE_URL + ApiConstant.SUBMIT_CIS_APMT_ACC_OPENING, data);
	}
	
	getBankNameListByCurrency(currency: string) {
		const params = this.paramService.buildHttpParams({currency});
		return this.http.post(this.BASE_URL + ApiConstant.GET_BANK_NAME_LIST_BY_CURRENCY, {}, {params});
	}
}