<div *ngIf="horizontal==true">
  <div *ngIf="highLight!=null">
    <a (click)="clickfromImage()">
      <div *ngIf="isWebcast" class="webcast-img">
        <img [src]="image" class="img-fluid pointer rounded w-100 cropped-img">
      </div>
      <div *ngIf="!isWebcast" class="webcast-img">
        <img [src]="image" class="img-fluid pointer rounded w-100">
      </div>
    </a>

    <p *ngIf="category" style="margin-bottom: 0.5rem;color: #7a7a7a;"
      [style]="(isMobile$|async)? 'font-size: 12px' : 'font-size: 19px'">{{category}}</p>
    <div class="py-2" style="min-height: 60px;margin-bottom: 0.5rem;"
      [style]="(isMobile$|async)? '' : 'text-align: center'">
      <h5 *ngIf="level == 'beginner'" class="text-profit font-weight-bold mb-1"><span translate="menubar.stock.school.beginner" translate-default="Beginner"></span></h5>
      <h5 *ngIf="level == 'intermediate'" class="text-warning font-weight-bold mb-1"><span translate="menubar.stock.school.intermediate" translate-default="Intermediate"></span></h5>
      <h5 *ngIf="level == 'advanced'" class="text-theme font-weight-bold mb-1"><span translate="menubar.stock.school.advanced" translate-default="Advanced"></span></h5>
      <a style="color: #000;font-weight: bold;" [style]="(isMobile$|async)? 'font-size: 16px' : 'font-size: 24px'"
        (click)="clickfromTitle()">{{title}}</a>
    </div>
    <div [style]="(isMobile$|async)? '' : 'text-align: center'">
      <h5 style="margin-bottom: 0.5rem;color: #7a7a7a;margin-bottom: 1rem;" *ngIf="desc"><span [innerHTML]="desc"></span></h5>
    </div>
    <div style="margin-top: 0.5rem;">
      <div *ngIf="author" [style]="(isMobile$|async)? '' : 'text-align: center'">
        <h6>{{author}}</h6>
      </div>
      <div [style]="(isMobile$|async)? '' : 'text-align: center'">
        <h6>
          <div style="display:inline" *ngIf="date"><i class="px-1" nz-icon nzType="calendar" nzTheme="outline"> </i>{{
            date | hkDate:null:dateFormat }}</div>
          <div style="display:inline" *ngIf="view"><i class="px-1" nz-icon nzType="eye" nzTheme="outline"> </i> {{view}}
          </div>
        </h6>
      </div>
    </div>
  </div>

  <div *ngIf="highLight==null">
    <div nz-row [nzGutter]="10" nzType="flex" class="b2c-article-list isMobile ant-row"
      style="margin-left: -5px; margin-right: -5px;">
      <div nz-col nzSpan="15" class="align-self-stretch d-flex flex-column justify-content-between ant-col ant-col-15">
        <p *ngIf="category" style="margin-bottom: 0.5rem;color: #7a7a7a;"
          [style]="(isMobile$|async)? 'font-size: 12px' : 'font-size: 19px'">{{category}}</p>
        <div style="min-height: 60px;margin-bottom: 0.5rem;">
          <h5 *ngIf="level == 'beginner'" class="text-profit font-weight-bold mb-1"><span translate="menubar.stock.school.beginner" translate-default="Beginner"></span></h5>
          <h5 *ngIf="level == 'intermediate'" class="text-warning mb-1"><span translate="menubar.stock.school.intermediate" translate-default="Intermediate"></span></h5>
          <h5 *ngIf="level == 'advanced'" class="text-theme font-weight-bold mb-1"><span translate="menubar.stock.school.advanced" translate-default="Advanced"></span></h5>
          <a style="color: #000;font-weight: bold;" [style]="(isMobile$|async)? 'font-size: 14px' : 'font-size: 24px'"
            (click)="clickfromTitle()">{{title}}</a>
          <h5 style="margin-bottom: 0.5rem;color: #7a7a7a;margin-bottom: 1rem;" *ngIf="desc"><span [innerHTML]="desc"></span></h5>
        </div>
        <div style="margin-top: 0.5rem;" *ngIf="!isMobileView">
        	<div class="d-flex footer-wrap">
    			<ng-container *ngIf="author">
  					<p class="text-black-7 font-weight-bold"  *ngIf="author!=null&&author!=''"> by {{author}}</p>
  				</ng-container>
   				<nz-divider *ngIf="date" nzType="vertical" class="h-auto bg-black-7 divider"></nz-divider>
    			<ng-container *ngIf="date">
    				<i class="px-1" nz-icon nzType="calendar" nzTheme="outline"></i>{{ date | hkDate:null:dateFormat }}
  				</ng-container>
    			<nz-divider *ngIf="view" nzType="vertical" class="h-auto bg-black-7 divider"></nz-divider>
    			<ng-container *ngIf="view">
      				<i class="ml-2 mr-1 fsmicon-eye-open-o"></i><p class="text-black-6 mb-0">{{view}} {{'views' | translate}}</p>
  				</ng-container>
  				<ng-container>  					
     				<i *ngIf="authState" (click)="checkSavedArticle()" class="ml-2 fsmicon-bookmart-o" [ngClass]="{'fsmicon-bookmart-o': !articleSaved,'fsmicon-bookmart': articleSaved}"></i>
     				<i (click)="shareLink()" nz-icon nzType="share-alt" nzTheme="outline" class="ml-2 share-icon"></i>
 				</ng-container>
  			</div>
        </div>
        <div style="margin-top: 0.5rem;" *ngIf="isMobileView">
        	<ng-container *ngIf="author">
            	<p class="text-black-7 font-weight-bold"  *ngIf="author!=null&&author!=''"> by {{author}}</p>
         	</ng-container>
          	<div>
	            <h6>
		            <ng-container style="display:inline" *ngIf="date">
		             	<i class="px-1" nz-icon nzType="calendar" nzTheme="outline"></i>{{ date | hkDate:null:dateFormat }}
		            </ng-container>
		            <ng-container style="display:inline" *ngIf="view">
		             	<i class="px-1" nz-icon nzType="eye" nzTheme="outline"></i>{{view}} {{'views' | translate}}
		            </ng-container>
		            <ng-container>
		              	<i *ngIf="authState" (click)="checkSavedArticle()" class="ml-2 align-middle fsmicon-bookmart-o" [ngClass]="{'fsmicon-bookmart-o': !articleSaved,'fsmicon-bookmart': articleSaved}"></i>
					  	<i (click)="shareLink()" nz-icon nzType="share-alt" nzTheme="outline" class="ml-2 align-middle"></i>
					</ng-container>
	            </h6>
          	</div>
        </div>
      </div>
      <div nz-col nzSpan="9" style="padding-left: 5px; padding-right: 5px;">
        <a (click)="clickfromImage()">
          <div *ngIf="isWebcast" class="webcast-img">
            <img [src]="image" class="img-fluid pointer rounded w-100 cropped-img-2">
          </div>
          <div *ngIf="!isWebcast" class="webcast-img">
            <img [src]="image" class="img-fluid pointer rounded w-100">
          </div>
        </a>
      </div>
    </div>
  </div>
</div>



<div *ngIf="horizontal==false">
  <div nz-row [nzGutter]="20" nzType="flex" [nzJustify]="((isMobile$ | async)?'space-between':'start')"
    nzJustify="space-between" nzAlign="middle" class="grid">
    <div nz-col nzSpan="12" [nzOrder]="(isMobile$|async)? 2 : 1" class="column w-100 h-100 ">
      <a (click)="clickfromImage()">
      	<div *ngIf="isWebcast">
      	  <img [src]="image" class="img-fluid pointer rounded mb-1 w-100 cropped-img-3">
      	</div>
      	<div *ngIf="!isWebcast">
      	  <img [src]="image" class="img-fluid pointer rounded mb-1 w-100">
      	</div>
      </a>
    </div>
    <div nz-col nzSpan="12" [nzOrder]="(isMobile$|async)? 1 : 2" class="column h-100 w-100 space-between-vertical">
      <div class="py-2" style="min-height: 60px;margin-bottom: 0.5rem;">
        <h5 *ngIf="level == 'beginner'" class="text-profit font-weight-bold mb-1"><span translate="menubar.stock.school.beginner" translate-default="Beginner"></span></h5>
        <h5 *ngIf="level == 'intermediate'" class="text-warning font-weight-bold mb-1"><span translate="menubar.stock.school.intermediate" translate-default="Intermediate"></span></h5>
        <h5 *ngIf="level == 'advanced'" class="text-theme font-weight-bold mb-1"><span translate="menubar.stock.school.advanced" translate-default="Advanced"></span></h5>
        <a style="color: #000;font-weight: bold;" (click)="clickfromTitle()">{{title}}</a>
        <h5 style="margin-bottom: 0.5rem;color: #7a7a7a;margin-bottom: 1rem;" *ngIf="desc"><span [innerHTML]="desc"></span></h5>
      </div>
      <div style="margin-top: 0.5rem;">
        <div *ngIf="author">
          <h6 class="pl-1">{{author}}</h6>
        </div>
        <div>
          <h6>
            <div style="display:inline" *ngIf="date"><i class="px-1" nz-icon nzType="calendar" nzTheme="outline"> </i>{{
              date | hkDate:null:dateFormat }}</div>
            <div style="display:inline" *ngIf="view"><i class="px-1" nz-icon nzType="eye" nzTheme="outline"> </i>
              {{view}}</div>
          </h6>
        </div>
      </div>
    </div>
  </div>
</div>